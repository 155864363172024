import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row g-10 dashboard-card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { style: {"padding":"14px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-md-3",
        key: item.id
      }, [
        _createVNode(_component_el_card, {
          "body-style": { padding: '0px' },
          onClick: ($event: any) => (_ctx.openDashboard(item)),
          class: "cursor-pointer"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: item.thumbnail,
              class: "image"
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, _toDisplayString(item.description), 1)
            ])
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]))
    }), 128))
  ]))
}