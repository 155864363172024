
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "Dashboard",

  setup() {
    const store = useStore();
    const router = useRouter();
    const header = ref([
      { column: "Authors", key: "name" },
      { column: "Company", key: "companyName" },
      { column: "Progress", key: "value" },
      { column: "", key: "action" },
    ]);
    const currentDate = ref(new Date());
    const elements = ref([] as any);

    const loadImg = (img) => {
      img = img.substring(1);
      console.log(`${process.env.VUE_APP_API_URL}${img}`);
      return `${process.env.VUE_APP_API_URL}${img}`;
    };

    const openDashboard = (item) => {
      router.push({ name: "dashboardItem", params: { item_id: item.id } });
    };

    onMounted(async () => {
      store.commit("setLoadingStatus", true);
      setCurrentPageBreadcrumbs("dashboard", []);
      store.commit("setLoading", false);
      await store.dispatch("loadElements");
      store.commit("setLoading", false);
      ApiService.get("/api/dashboards/").then(({ data }) => {
        data.data.forEach((x) => {
          elements.value.push({
            id: x.id,
            description: x.description,
            thumbnail: x.thumbnail,
            url: x.url,
          });
        });
        store.commit("setLoadingStatus", false);
      });
    });

    return {
      elements,
      header,
      currentDate,
      loadImg,
      openDashboard,
    };
  },
});
